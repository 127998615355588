import {AfterViewInit, Component, ElementRef, ViewChild, ViewContainerRef} from '@angular/core';
import { AppStateService } from 'services/app/app-state.service';
import { DbReaderService } from "services/db-reader/db-reader.service";
import { Router } from "@angular/router";
import {Observable, of, timer} from 'rxjs';
import { environment } from 'environments/environment';
import { KundeService } from 'services/kunden/kunde.service';
import { MandantenService } from 'services/mandanten/mandanten.service';
import { MitteilungsService } from 'services/prozesse/mitteilung.service';
import { BestellungService } from 'services/bestellung/bestellung.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'submodul/obs.shared/dialog/confirm-dialog/confirm-dialog.components';
import {LoginComponent} from 'submodul/obs.shared/componenten/login/login.component';
import {MitteilungenService} from '../../../submodul/obs.shared/componenten/mitteilungen/mitteilungen.service';

@Component({
  selector: 'profile',
  templateUrl: './mobil-profile.component.html',
  styleUrls: ['./mobil-profile.component.css'],
})
export class MobilProfileComponent implements AfterViewInit {

  @ViewChild("profil_login", { read: ElementRef }) profil_login: ElementRef
  @ViewChild('loginComponent', { static: true, read: ViewContainerRef })
  public loginComponentRef: ViewContainerRef
  public isLoginComponentRef: boolean = false
  public profils = []
  public aktives_profil
  public mandant

  constructor(
    public dialog: MatDialog,
    private app_state: AppStateService,
    private db: DbReaderService,
    private router: Router,
    private mandant_service: MandantenService,
    private kunde_service: KundeService,
    private bestellungen_service: BestellungService,
    private mitteilungs_service: MitteilungsService,
    private mitteilungenService: MitteilungenService
  ) {
    this.app_state.set_page_name('Benutzerübersicht & -informationen');
    this.app_state.set_seitenname('profile')
    this.app_state.set_page_navigation('normal');
  }

  ngAfterViewInit() {
    this.mandant_service.mandant_get().subscribe(mandant => {
      this.mandant = mandant
    })
    this.get();
  }

  public get() {
    this.profils = []
    this.db.get('active', 'User').subscribe(active => {
      this.aktives_profil = active
      this.db.all('User', 'value').subscribe(results => {
        for (const result of results) {
          if (typeof result == 'object') {
            result.visibility = false
            if (environment.mandant_id + result.login === active) {
              result.activ = true
            }
            this.profils.push(result)
          }
        }
        if (this.profils.length == 0){
          this.router.navigateByUrl('login')
        }
      })
    })
  }

  public profil_wechseln(element, kunde) {
    let confirm: any = of(true)
    if (this.bestellungen_service._bestellungen_anzahl.getValue() > 0) {
      confirm = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: "",
          message: `Sie haben offene Bestellvorgänge im Warenkorb. Möchten Sie den Benutzer trotzdem wechseln?`,
          panelClass: 'dialog-container-bestellschluss',
        }
      }).afterClosed();
    }
    confirm.subscribe((result) => {
      if (result){
        if (element.checked) {
          this.db.update(kunde.kundeId, 'active', 'User')
          localStorage.setItem('active', kunde.kundeId)
          this.mandant_service.mandant_login(kunde.mandantId)
          this.mitteilungs_service.set_snackbar('8107', 2000)
          this.mitteilungenService.holeMitteilungen()
        } else {
          this.db.delete('active')
          localStorage.removeItem('active')
          this.mandant_service.mandant_login(environment.mandant_id)
          this.mitteilungs_service.set_snackbar('1004', 2000)
        }
        localStorage.removeItem(`werbepopup_${kunde.kundeId}`)
        this.bestellungen_service.bestellungen_leeren()
        if (localStorage.getItem('active')){
          this.kunde_service.update.next(true)
        } else {
          this.kunde_service.update.next(false)
        }
      }
      this.get()
    })
  }

  renderLoginComponent(){
    document.getElementById('profilNeuKarte').classList.add('fade-in')
    timer(1200).subscribe(
      () => document.getElementById('profilNeuKarte').classList.remove('fade-in')
    )
    const loginComponentRef = this.loginComponentRef.createComponent(LoginComponent)
    loginComponentRef.instance.loginEvent.subscribe((emitter:Observable<any>) => {
      emitter.subscribe(
        login => {
          this.get()
          timer(1000).subscribe(() => {
            this.router.navigateByUrl('speiseplan-kunde')
          })
        },
        errors => {
          let errorCode = errors.error.content.msgId
          this.mitteilungs_service.set_snackbar(errorCode, 4000)
        })
    });
    this.isLoginComponentRef = true
  }

  unrenderLoginComponent(){
    document.getElementById('profilNeuKarte').classList.add('fade-in')
    timer(1200).subscribe(
      () => document.getElementById('profilNeuKarte').classList.remove('fade-in')
    )
    this.loginComponentRef.clear()
    this.isLoginComponentRef = false
  }

  public link(profil) {
    this.router.navigate(['profil', profil.kundeId])
  }

  public logout(id) {
    let text = this.bestellungen_service._bestellungen_anzahl.getValue() ? 'Sie haben offene Bestellvorgänge im Warenkorb.' : ''
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Benutzer entfernen",
        message: `${text} Möchten Sie den Benutzer entfernen?`,
        panelClass: 'dialog-container-bestellschluss',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.bestellungen_service.bestellungen_leeren()
        this.kunde_service.kunde_logout(id).subscribe(() => {
          this.get()
        })
      }
    });
  }
}
