import {Component, OnInit} from '@angular/core';
import { AppStateService } from 'services/app/app-state.service';
import {RegistrierungService} from '../../../services/registrierung/registrierung.service';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {MitteilungsService} from '../../../services/prozesse/mitteilung.service';
import {MandantenService} from '../../../services/mandanten/mandanten.service';

@Component({
  selector: 'app-content-home',
  templateUrl: './mobil-login.component.html',
  styleUrls: ['./mobil-login.component.css']
})
export class MobilLoginComponent implements OnInit {

  public logo: string = "assets/web/logo.webp";


  constructor(
    private app_state_service: AppStateService,
    public registrationService: RegistrierungService,
    private router: Router,
    private mitteilungsService: MitteilungsService,
    private mandant_service: MandantenService,
  ) {
    this.app_state_service.set_page_name('Anmelden');
    this.app_state_service.set_seitenname('login')
    this.app_state_service.set_page_navigation('normal');
  }

  ngOnInit() {}

  onLogin(loginEvent: Observable<any>){
    loginEvent.subscribe(
      () => {
        this.wbaLoggedIn()
      }, errors => {
        document.getElementById('passwortVergessen').classList.add('heartbeat')
        setTimeout(() => {
          document.getElementById('passwortVergessen').classList.remove('heartbeat')
        }, 1000)
        let errorCode = errors.error.content.msgId
        this.mitteilungsService.set_snackbar(errorCode, 4000)
      });
  }

  public wbaLoggedIn(): void {
    this.mandant_service.mandant_get().subscribe(mandant => {
      if (mandant.navigation_logged_in_default === 'home') {
        this.router.navigateByUrl('/home')
      } else if (mandant.navigation_logged_in_default === 'menu') {
        this.router.navigateByUrl('/speiseplan-kunde')
      }
    })
  }

  // TODO: hier nen Service drauß machen, ich bin zu faul grad lol
  onImageError() {
    this.logo = "assets/web/icon-1024x1024.webp";
  }
}
