<div id="profile">
  <div id="profileContent">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let profil of profils">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <mat-checkbox *ngIf="!profil.order4NewPw" [checked]="profil.kundeId == aktives_profil" (change)="profil_wechseln($event, profil)"></mat-checkbox>
            <div class="profilDetails">
              <strong>{{ profil.essensteilnehmer.vorname }}</strong>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="profilWeitereInforamtionen">
          <strong>Benutzerinformationen</strong>
          <div class="profilWeitereInforamtionenItem">
            <span>Kundennummer:</span>
            <span>{{ profil.kundenNr }}</span>
          </div>
          <div class="profilWeitereInforamtionenItem" *ngIf="profil.einrichtung.nameEinrichtung">
            <span>Einrichtung:</span>
            <span>{{ profil.einrichtung.nameEinrichtung }}</span>
          </div>
          <div class="profilWeitereInforamtionenItem" *ngIf="profil.essensteilnehmer.eMail">
            <span>Email:</span>
            <span>{{ profil.essensteilnehmer.eMail }}</span>
          </div>

          <div class="profil-option">
            <button mat-flat-button (click)="link(profil)" [disabled]="profil.kundeId !== aktives_profil">
              <span>Benutzer einsehen & bearbeiten</span>
            </button>
            <button mat-flat-button (click)="logout(profil.kundeId)">
              <span>Benutzer aus der App ausloggen</span>
            </button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <div id="profilNeu">
      <img src="assets/icons/Kochtopf.webp" alt="Kochtopf">
      <mat-card>
        <div id="profilNeuKarte">
          <mat-icon *ngIf="isLoginComponentRef" (click)="unrenderLoginComponent()">close</mat-icon>
          <ng-template #loginComponent></ng-template>
          <button mat-raised-button *ngIf="!isLoginComponentRef" (click)="renderLoginComponent()" color="secondary">
            <span>Neuen Benutzer hinzufügen</span>
          </button>
        </div>
      </mat-card>
    </div>
  </div>
</div>
