import {Component, OnDestroy, OnInit} from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AppStateService } from 'services/app/app-state.service';
import { RevisionService } from 'services/api/revision.service';
import {AnkuendigungService} from '../../services/prozesse/ankuendigung.service';
import {NavigationEnd, Router} from '@angular/router';
import {RouterService} from '../../services/navigation/router.service';
import {LoadingService} from '../../services/prozesse/loading.service';
import {LadeanzeigeService} from '../../services/prozesse/ladeanzeige.service';
import {interval, timer} from 'rxjs';
import {PwaInstallComponent} from '../../submodul/obs.shared/componenten/pwa-install/pwa-install.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {PwaInstallationComponent} from '../../submodul/obs.shared/dialog/pwaInstallation/pwaInstallation.component';
import {MitteilungenService} from '../../submodul/obs.shared/componenten/mitteilungen/mitteilungen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent implements OnInit, OnDestroy {
  public kunde: boolean | object = false;
  public loadingOverlay: boolean = false
  public loadingOverlaySubscription

  constructor(
    public app_state_service: AppStateService,
    private revisions_service: RevisionService,
    private ankuendigungsService: AnkuendigungService,
    private router_service: RouterService,
    private ladeanzeigenService: LadeanzeigeService,
    private router: Router,
    private dialog: MatDialog,
    private mitteilungenService: MitteilungenService,
  ) {
    router.events.subscribe(event => {
      this.router_service.regLoeschen()
      if (event instanceof NavigationEnd){
        this.router_service.revisionsKontrolle(this.router.url.split('/')[1])
      }
    })
  }

  ngOnInit() {
    if (!localStorage.getItem('readIosPwaInstall')) {
      this.dialog.open(PwaInstallationComponent, {}).afterClosed().subscribe(() => {
        if (this.mitteilungenService.pruefeMitteilungenObLeer()){
          this.mitteilungenService.dialogOpen()
        }
      })
    }
    this.app_state_service.pruefeUpdateBeiRoute()
    this.loadingOverlaySubscription = this.ladeanzeigenService.loadingOverlay.subscribe(
      loadingOverlay => {
        timer(250).subscribe(() => {
          if (this.loadingOverlay !== this.ladeanzeigenService.loadingOverlay.value) {
            this.loadingOverlay = this.ladeanzeigenService.loadingOverlay.value
          }
        })
      }
    )
  }

  ngOnDestroy() {
    this.loadingOverlaySubscription.unsubscribe()
  }
}
