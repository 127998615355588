import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppStateService } from 'services/app/app-state.service';
import { zip } from 'rxjs';
import { BestellungService } from 'services/bestellung/bestellung.service';
import { KundeService } from 'services/kunden/kunde.service';
import { MandantenService } from 'services/mandanten/mandanten.service';
import { SpeiseplanService } from 'submodul/obs.speiseplan/services/speiseplan.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SpeiseplanDetailsComponent} from '../../../submodul/obs.speiseplan/einzelbesteller/speiseplanDetails/speiseplanDetails.component';
import {MobilBestellhistoriePickerComponent} from '../mobil-bestellhistorie_picker/mobil-bestellhistorie_picker.component';
import {DateRange} from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'mobil-bestellhistorie',
  templateUrl: './mobil-bestellhistorie.component.html',
  styleUrls: ['./mobil-bestellhistorie.component.css'],
})
export class MobilBestellhistorieComponent implements OnInit {

  @ViewChild('speiseplan_kalender') speiseplan_kalender
  @ViewChild('speiseplan_details') speiseplan_details
  public mandant
  public kunde = null
  public zeitraum
  public apiZeitraum
  public speiseplan
  public bestellung: any
  public bestellungLeer = false
  public speiseplanDaten
  public bestellungenDaten
  public bestellungenPreis = 0

  constructor(
    private speiseplan_service: SpeiseplanService,
    private mandant_service: MandantenService,
    private kunden_service: KundeService,
    private bestellung_service: BestellungService,
    private route: ActivatedRoute,
    private state_service: AppStateService,
    private matDialog: MatDialog,
  ) {
    this.state_service.set_page_name('Bestellhistorie');
    this.state_service.set_seitenname('bestellungen')
    this.state_service.set_page_navigation('normal');
    moment.locale('de')
  }

  ngOnInit() {
    zip(
      this.speiseplan_service.speiseplan_zeitraum(),
      this.mandant_service.mandant_get(),
      this.kunden_service._kunde_get()
    ).subscribe(internResponse => {
      this.apiZeitraum = internResponse[0]
      this.mandant = internResponse[1]
      this.kunde = internResponse[2]
      zip(

        this.speiseplan_service.speiseplan_kunde(this.kunde.bestellerId, this.apiZeitraum.von, this.apiZeitraum.bis),
        this.bestellung_service.bestellung_kunde(this.kunde.bestellerId, this.apiZeitraum.von, this.apiZeitraum.bis)
      ).subscribe(apiResponse => {
        this.speiseplanDaten = apiResponse[0]
        this.bestellungenDaten = apiResponse[1]
        if (this.route.snapshot.paramMap.get('zeitraum')) {
          let urlZeitraum = JSON.parse(this.route.snapshot.paramMap.get('zeitraum'))
          this.zeitraum = {
            von: moment.default(urlZeitraum.von),
            bis: moment.default(urlZeitraum.bis)
          }
        }else{
          this.zeitraum = {
            von: moment.default(new Date()).startOf('week'),
            bis: moment.default(new Date()).endOf('week')
          }
        }
        this.speiseplan_laden()
      })
    })
  }

  speiseplan_laden() {
    this.bestellung = {
      menutexte: this.speiseplanDaten.menuetexte,
      tage: {}
    }
    this.bestellungenPreis = 0

    for (let bestellTag in this.bestellungenDaten.kunden[this.kunde.bestellerId].tage){
      let unixBestellTag = moment.default(bestellTag).unix()
      if (this.zeitraum.von.unix() <= unixBestellTag && this.zeitraum.bis.unix() >= unixBestellTag){
        let tag = this.bestellungenDaten.kunden[this.kunde.bestellerId].tage[bestellTag]
        for (let menu_id in tag.bestellMenues) {
          let menu = tag.bestellMenues[menu_id]
          if (menu.mengeNeu == menu.mengeAlt) {
            delete tag.bestellMenues[menu_id]
          }else{
            this.bestellungenPreis += menu.gesamtBetrag.betrag
          }
        }
        if (Object.keys(tag.bestellMenues).length !== 0){
          this.bestellung.tage[bestellTag] = tag
        }
      }
    }
    this.bestellungLeer = Object.keys(this.bestellung.tage).length > 0 ? false : true
  }

  public bestellungZeitraum(){
    const kalenderRef =this.matDialog.open(MobilBestellhistoriePickerComponent)
    kalenderRef.componentInstance.apiZeitraum = this.apiZeitraum
    kalenderRef.componentInstance.dateRange = new DateRange(moment.default(this.zeitraum.von), moment.default(this.zeitraum.bis))
    kalenderRef.componentInstance.bestellungen = this.bestellungenDaten.kunden[this.kunde.bestellerId]
    kalenderRef.afterClosed().subscribe(zeitraum => {
      if (zeitraum){
        this.zeitraum = zeitraum
        this.speiseplan_laden()
      }
    })
  }

  public bestellungDetails (menu) {
    menu['menueText'] = this.bestellung.menutexte[menu.menueTextId].menueText
    menu['bezeichnung'] = this.bestellung.menutexte[menu.menueTextId].bezeichnung
    menu['symbol'] = this.bestellung.menutexte[menu.menueTextId].symbol
    menu['naehrwerte'] = this.bestellung.menutexte[menu.menueTextId].naehrwerte
    menu['allergene'] = this.bestellung.menutexte[menu.menueTextId]['allergeneIds']
    menu['inhaltsstoffe'] = this.bestellung.menutexte[menu.menueTextId]['inhaltsstoffeIds']
    menu['zusatzstoffe'] = this.bestellung.menutexte[menu.menueTextId]['zusatzstoffeIds']
    const matDialogRef = this.matDialog.open(SpeiseplanDetailsComponent, { width: '90%' });
    matDialogRef.componentInstance.menu = menu
    matDialogRef.componentInstance.mandant = this.mandant
  }
}
