import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {ApiCallService} from '../../../services/api/api-call.service';
import {map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AnkuendigungPopupComponent} from './ankuendigung-popup/ankuendigung-popup.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

@Injectable()
export class AnkuendigungService {

  public ankuendigungsBannerContent
  public ankuendigungsPopupContent

  constructor(
    private apiCallService: ApiCallService,
    private matDialog: MatDialog
  ) {}

  public ankuendigungsContentPopup(){
    return this.apiCallService.request('/public', 'content/get', {
      mandantId: environment.mandant_id ,
      'contentItemIds': JSON.stringify([]),
      zweck: 'App_Werbebanner',
      lang: 'de'
    }).pipe(
      switchMap((apiResponse) => {
        if (apiResponse && apiResponse[0]){
          this.ankuendigungsBannerContent = atob(apiResponse[0].content)
          return this.apiCallService.request('/public', 'content/get', {
            mandantId: environment.mandant_id ,
            'contentItemIds': JSON.stringify([]),
            zweck: 'App_Werbepopup',
            lang: 'de'
          })
        }else{
          return of(false)
        }
      }),
      map((apiAnkuendigungsPopupResponse) => {
        if (apiAnkuendigungsPopupResponse){
          this.ankuendigungsPopupContent = atob(apiAnkuendigungsPopupResponse[0].content)
          return true
        }else{
          return false
        }
      })
    )
  }

  public ankuendigungsPopup(mandant, kunde) {
    const dialogRef = this.matDialog.open(AnkuendigungPopupComponent, {
      data: {werbeTitel: mandant.sonderKonfiguration['werbepopup']},
      panelClass: 'werbePopup'
    })
    dialogRef.afterClosed().subscribe(result => {
      if(!localStorage.getItem(`${kunde.kundeId}_werbebanner`)){
        localStorage.setItem(`${kunde.kundeId}_werbebanner`, 'true')
      }
    })
  }
}

