import {Component, OnInit, AfterViewInit, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {PwaInstallComponent} from '../../componenten/pwa-install/pwa-install.component';

@Component({
  selector: 'pwaInstallation',
  templateUrl:  './pwaInstallation.component.html',
  styleUrls: ['./pwaInstallation.component.css'],
})

export class PwaInstallationComponent implements OnInit, AfterViewInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data:any,
    public dialogRef: MatDialogRef<PwaInstallComponent>,
  ) {
    console.log(this.data)
  }

  ngOnInit() {}

  ngAfterViewInit() {}

  public close(){
    this.dialogRef.close()
  }
}
