import { Component } from '@angular/core';
import * as moment from 'moment';
import { DateRange } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
    selector: 'mobil-bestellhistorie_picker',
    templateUrl: './mobil-bestellhistorie_picker.component.html',
    styleUrls: ['./mobil-bestellhistorie_picker.component.css'],
    providers: [
      {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
      {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
      {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    ],
})
export class MobilBestellhistoriePickerComponent {

  public apiZeitraum
  public dateRange: DateRange<any>
  public bestellungen

    constructor(
      public dialogRef: MatDialogRef<MobilBestellhistoriePickerComponent>,
    ) {}

  public selectedChange(event){
      if (!this.dateRange?.start || this.dateRange?.end) {
        this.dateRange = new DateRange<Date>(event, null);
      } else {
        const start = this.dateRange.start;
        const end = event;
        if (end < start) {
          this.dateRange = new DateRange<Date>(end, start);
        } else {
          this.dateRange = new DateRange<Date>(start, end);
        }
      }
    }

  markiereBestellungen(){
    return (date: Date) => {
      let _date = moment.default(date)
      const isoDate = _date.format('YYYY-MM-DD')
      let bestellungen = 0
      if (this.bestellungen.tage[isoDate]){
        for (let index in this.bestellungen.tage[isoDate].bestellMenues){
          if (this.bestellungen.tage[isoDate].bestellMenues[index].mengeAlt > 0){
            bestellungen ++
          }
        }
        if (bestellungen > 0){
          return 'special-date'
        }
        return
      }
    };
  }

    aktuelle_woche(){
      const von = moment.default().locale('de').startOf('week')
      const bis = moment.default().locale('de').endOf('week')
      this.dateRange = new DateRange<any>(von, bis)
    }

    aktueller_monat(){
      const von = moment.default().locale('de').startOf('month')
      const bis = moment.default().locale('de').endOf('month')
      this.dateRange = new DateRange<any>(von, bis)
    }

    public speichern(){
      let von = moment.default(this.dateRange.start)
      let bis = moment.default(this.dateRange.end)
      if (bis.format('YYYY-MM-DD') === 'Invalid date'){ bis = von }
      this.dialogRef.close({
        von: von,
        bis: bis
      })
    }
}
